@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .gradient-text {
        background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}